@import './variables.scss';

@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Pragati+Narrow:wght@400;700&display=swap');

html, body, div, p, main, section, footer, header, h1, h2, h3 {
    margin: 0;
    padding: 0;
}

html {
    font-family: var(--font1);
    font-size: 22px;

    @media (max-width: 870px) {
        font-size: 18px;
    }

    @media (max-width: 600px) {
        font-size: 16px;
    }
}

body {
    background: var(--color2);
    color: white;

    min-width: 390px;
}
